
import {mapActions, mapGetters} from 'vuex'

  const GCNavigationSide = () => import('~/components/layout/sideNavigation/subviews/gaming-club/GCNavigationSide.vue')
  const GCPlayerAccountSide = () => import('~/components/layout/sideNavigation/subviews/gaming-club/GCPlayerAccountSide')
  const GCSeasonRanks = () => import('~/components/layout/sideNavigation/subviews/gaming-club/GCSeasonRanks')
  const GCLeagueHistorySide = () => import('~/components/layout/sideNavigation/subviews/gaming-club/GCLeagueHistorySide')
  const TournamentsList = () => import('~/components/layout/sideNavigation/subviews/TournamentsList')
  const GCCreateAccountBanner = () => import('~/components/layout/sideNavigation/subviews/gaming-club/GCCreateAccountBanner')
  const GCDiscordBanner = () => import('~/components/layout/sideNavigation/subviews/gaming-club/GCDiscordBanner')

  export default {
    name: 'GamingClubWrapper',
    props: {},
    data () {
      return {
        sidebarComponents: []
      }
    },
    computed: {
      ...mapGetters({
        isAuthenticated: 'authentication/isAuthenticated',
        hasEnrolledTournaments: 'gamingClub/hasEnrolledTournaments',
      })
    },
    watch: {
      hasEnrolledTournaments: {
        handler() {
          this.prepareRemap()
        }
      },
      isAuthenticated: {
        handler() {
          this.prepareRemap()
        }
      },
      '$route': {
        immediate: true,
        handler() {
          this.prepareRemap()
        }
      }
    },
    mounted () {
      this.fetchDependencies()
    },
    methods: {
      ...mapActions({
        fetchDependencies: 'gamingClub/fetchDependencies'
      }),
      showLoginModal() {
        this.$modal.showLogin()
        },

      prepareRemap() {
        let leagueId = null

        if('leagueId' in this.$route.params && this.$route.params.leagueId) {
          leagueId = this.$route.params.leagueId
        }

        this.remapComponents(leagueId)
      },

      remapComponents(leagueId) {
        const isAuthenticated = this.isAuthenticated
        const components = [
          GCNavigationSide
        ]

        if(isAuthenticated) {
          components.push(GCPlayerAccountSide)

          if(this.hasEnrolledTournaments) {
            components.push(TournamentsList)
          }
        } else {
          components.push(GCCreateAccountBanner)
        }

        components.push(GCDiscordBanner)

        if (leagueId) {
          components.push(...[
            GCSeasonRanks,
            GCLeagueHistorySide
          ])
        }

        this.sidebarComponents = components
      }
    }
  }
